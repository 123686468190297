import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const SocialMedia = () => {
    const handleIconClick = (type) => {
        if(type === 'github')
            window.open('https://github.com/yangzho12138');
        if(type === 'linkedin')
            window.open('https://www.linkedin.com/in/yangzho12138/');
    }

    return (
        <>
            <Row className="social-media">
                <div className='connect'>Connect With Me</div>
                <Col md={6}>
                    <Row style={{display: 'flex', justifyContent: 'center'}}>
                        <FontAwesomeIcon  className="icon" icon={faGithub} flip onClick={() => handleIconClick("github")}/>
                    </Row>
                </Col>
                <Col md={6}>
                    <Row style={{display: 'flex', justifyContent: 'center'}}>
                        <FontAwesomeIcon  className="icon" icon={faLinkedin} flip onClick={() => handleIconClick("linkedin")}/>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default SocialMedia;