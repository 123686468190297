import React from 'react';
import { Card } from 'react-bootstrap'

const Modal = ({ isOpen, onClose, title, subtitle, children }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <Card className="modal-window">
                <button className="modal-close-button" onClick={onClose}>
                    &times;
                </button>
                <br />
                <Card.Title>{title}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">{subtitle}</Card.Subtitle>
                {children}
            </Card>
        </div>
    );
};

export default Modal;
