import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCode, faShieldHalved } from '@fortawesome/free-solid-svg-icons'
import { Card } from 'react-bootstrap';
import { faJava, faPython, faReact, faJs, faGolang, faAndroid, faVuejs, faRust } from '@fortawesome/free-brands-svg-icons'

const ProjectCard = ({ project }) => {
    return(
        <Card style={{marginTop: "5%", height: "90%"}}>
            <Card.Body style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Card.Text>
                <div><b>Name</b>: {project.title}</div>
                <div><b>Category</b>: {project.category}</div>
                <div><b>Tech</b>: {project.tech}</div>
                {project.link && (
                    <>
                        <b>Code: </b>
                        <a href={project.link}>
                            <FontAwesomeIcon icon={faCode} />
                        </a>
                    </>
                )}
                </Card.Text>
                {project.icon === "Java" && (
                    <FontAwesomeIcon icon={faJava} style={{width: "30%", height: "30%"}} />
                )}
                {project.icon === "Python" && (
                    <FontAwesomeIcon icon={faPython} style={{width: "30%", height: "30%"}} />
                )}
                {project.icon === "React" && (
                    <FontAwesomeIcon icon={faReact} style={{width: "30%", height: "30%"}}/>
                )}
                {project.icon === "Javascript" && (
                    <FontAwesomeIcon icon={faJs} style={{width: "30%", height: "30%"}}/>
                )}
                {project.icon === "Go" && (
                    <FontAwesomeIcon icon={faGolang} style={{width: "30%", height: "30%"}}/>
                )}
                {project.icon === "Android" && (
                    <FontAwesomeIcon icon={faAndroid} style={{width: "30%", height: "30%"}}/>
                )}
                {project.icon === "Vue" && (
                    <FontAwesomeIcon icon={faVuejs} style={{width: "30%", height: "30%"}}/>
                )}
                {project.icon === "Security" && (
                    <FontAwesomeIcon icon={faShieldHalved} style={{width: "30%", height: "30%"}}/>
                )}
                {project.icon === "Rust" && (
                    <FontAwesomeIcon icon={faRust} style={{width: "30%", height: "30%"}}/>
                )}
            </Card.Body>
        </Card>

    )
}

export default ProjectCard;