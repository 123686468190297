import React from 'react';
import { Row, Col } from 'react-bootstrap';
import ProjectCard from './ProjectCard';

const projectList = [
    {
        "title": "King of Bots",
        "category": "Full-Stack, Microservice, Multi-Threading, High-Concurrency",
        "tech": "Vue.js, SpringBoot, SpringCloud, SpringSecurity, MySQL, Redis, Websocket",
        "link": "https://github.com/yangzho12138/King-of-Bots",
        "icon": "Vue"
    },
    {
        "title": "Warlock-Battle",
        "category": "Full-Stack, Microservice, Multi-Threading",
        "tech": "JQuery, Django (RestFramework), SqlLite, Thrift, Websocket",
        "link": "https://github.com/yangzho12138/Warlock-Battle/tree/master/game",
        "icon": "Python"
    },
    {
        "title": "Recruitment Platform",
        "category": "Full-Stack",
        "tech": "React, Django, Flask, Sentry, Celery, Signal, RabbitMQ",
        "link": "https://github.com/yangzho12138/Recruitment",
        "icon": "React"
    },
    {
        "title": "TicketHub",
        "category": "Full-Stack, Microservice",
        "tech": "React, Express(Typescript), MongoDB, Redis, Docker, K8s",
        "link": "https://github.com/yangzho12138/TicketHub",
        "icon": "Javascript"
    },
    {
        "title": "Distributed System Design",
        "category": "Distributed System",
        "tech": "ISIS Algorithm, Raft Algorithm, Distributed Transaction",
        "link": "https://github.com/yangzho12138/Distributed-System",
        "icon": "Go"
    },
    {
        "title": "Fly High",
        "category": "Android Application",
        "tech": "UI/UX Design, Android Development, RESTFul APIs, SqlLite",
        "link": "https://github.com/yangzho12138/Fly-High",
        "icon": "Android"
    },
    {
        "title": "Data Security",
        "category": "Data Security",
        "tech": "Interference, Private Set Intersection, Bitcoin Analysis, Andorid Malware, Password Cracking, Machine Learning",
        "link": "https://github.com/yangzho12138/CS463-Computer-Security-2",
        "icon": "Security"
    },
    {
        "title": "Guided Unit Test Generator",
        "category": "Software Testing",
        "tech": "Unit Test, Automatic Test Generation Tool",
        "link": "https://github.com/yangzho12138/Guided-Unit-Test-Generator",
        "icon": "Java"
    },
    {
        "title": "DeepOLA",
        "category": "Data Management",
        "tech": "Online Aggregation for Nested Querirs",
        "link": "https://github.com/yangzho12138/Deep-Online-Aggregation",
        "icon": "Rust"
    }
]

const Project = () => {
    return (
        <>
            <div className='project'>
                <Row>
                    <div className='project-title'>Explore More ...</div>
                </Row>
                <div className='project-area'>
                    <Row>
                        {projectList.map((project, index) => (
                            <Col key={index} xs={12} sm={8} md={6} lg={4}>
                                <ProjectCard project={project} />
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>
        </>
    )
}

export default Project;