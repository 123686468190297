import React from 'react'
import { Col, Row } from 'react-bootstrap'
import SocialMedia from '../components/SocialMedia'
import Internship from '../components/Internship'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons'
import Project from '../components/Project'

const HomeScreen = () => {
  return (
    <>
        <div className='background-canvas'>
            <div className='triangle1'> 
                {/* <a href='https://drive.google.com/file/d/1PqbxRQyHB6rsPb6QD3-RKV90_XGPcKdQ/view?usp=sharing' className="link-text">Resume</a>
                <br/> */}
                <div className='link-text'>YangZhou12138@outlook.com </div>
                <div className='link-text'>(+1) 480-270-1274</div>
            </div>
            <div className='triangle2'></div>
            <div className='portrait'></div>
            <Row className='name'>Yang Zhou</Row>
            <Row className='slogan slogan-top'>Better Software</Row>
            <Row className='slogan slogan-bottom'>Better World</Row>
            <Row className='text text-university'>University of Illinois Urbana-Champaign</Row>
            <Row className='text'>Master of Computer Science </Row>
            <Row className='text'>GPA: 3.89 </Row>
            <Row className='text text-universities'>Xidian University & Chongqing University </Row>
            <Row className='text'>Bachelor of Engineering in Software Engineering</Row>
            <Row className='text'>GPA: 3.90 </Row>
            <Row className='skill'>Java, Python, Go, C/C++, Javascript/Typescript, Node, HTML, CSS, Shell Script, SQL, NoSQL, Docker, K8s, AWS, Azure, Agile, UI/UX ......</Row>
        </div>
        <SocialMedia />
        <Internship />
        <div className='quote'>
          <Row>
          <Col md={4}>
            <FontAwesomeIcon icon={faQuoteLeft} className='quote-symbol'/>
          </Col>
            <Col md={8}>
              <div>
                <p className='quote-text'>Give Me A Chance</p>
                <p className='quote-text'>Give You A Surprise</p>
              </div>
            </Col>
          </Row>
        </div>
        <Project />
    </>
  )
}

export default HomeScreen

