import { Route, Routes } from 'react-router-dom';
import HomeScreen from './screens/HomeScreen';


function App() {
  return(
    <>
        <main>
          <Routes>
            <Route path='/' element={<HomeScreen />} />
          </Routes>
        </main>
    </>
  )
}

export default App;
