import React from 'react'
import { Card, Button } from 'react-bootstrap'
import { useState } from 'react';
import Modal from './Modal'

const InternCard = ({ intern }) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalSubtitle, setModalSubtitle] = useState("");
    const [modalContent, setModalContent] = useState("");

    const handleDetailClick = (intern) => {
        setModalTitle(intern.company);
        setModalSubtitle(intern.title);
        setModalContent(intern.description);
        setModalOpen(true);
    }

    return (
        <>
            <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)} title={modalTitle} subtitle={modalSubtitle}>
                <Card.Text>
                {modalContent.split('\n').map((line, index, array) => (
                    <React.Fragment key={index}>
                        {line}
                        {index < array.length - 1 && <br />}
                    </React.Fragment>
                ))}
                </Card.Text>
            </Modal>
            <div>
                <Card className='intern-card' style={{backgroundColor: "#972280"}}>
                    <div className="cut-rectangle" style={{ backgroundImage: 'url(' + intern.image + ')' }}></div>
                    <Card.Body>
                        <Card.Title style={{color: 'white'}}>{intern.company}</Card.Title>
                        <Card.Text style={{color: 'white'}}>
                            <div>{intern.title}</div>
                            <div>{intern.time}</div>
                            <div><b>Tags</b>: {intern.keywords}</div>
                        </Card.Text>
                        <Button variant="primary" onClick={() => handleDetailClick(intern)}>SEE DETAIL</Button>
                    </Card.Body>
                </Card>
            </div>
        </>
    )
}

export default InternCard