import React from 'react';
import { Row, Col } from 'react-bootstrap';
import InternCard from './InternCard';

const internshipList = [
    {
        "company": "TIMAN Lab, UIUC",
        "title": "Backend Developer",
        "time": "Sept, 2023 - Dec, 2023",
        "Address": "Champaign, IL",
        "keywords": "RESTFul APIs (Django + Nodejs), Test-driven Development, Code Reuse and Componentization, CI/CD",
        "description": "•	Developed a Python Django backend with RESTful Node.js APIs to retrieve information from external APIs (Bing and Open AI), customized Django administration system for permission control and log tracking, and integrated Sentry for exception monitoring \n•	Unified custom response format and error handler, and extracted common code into NPM Package, achieved 11.28% reuse rate \n•	Implemented test-driven development for RESTful Node.js APIs using Jest with 100% statement/branch coverage \n•	Implemented a robust CI/CD pipeline with Jenkins to ensure seamless development workflows and application delivery \n",
        "image": "TIMAN.jpg"
    },
    {
        "company": "DTV Innovations",
        "title": "Software Engineer",
        "time": "Sept, 2023 - Dec, 2023",
        "Address": "Elgin, IL",
        "keywords": "Agile, Full-Stack Development (React + Java), API Management, Database Optimization, Testing & Maintenance, Containerization & Deployment",
        "description": "•	Collaborated with the team to develop Linux-based software using Agile methodology based on Jira Software\n•	Engineered a Full Stack signal monitoring application using React & Redux (frontend) and Play Framework with Java & Scala (backend), crafted reusable JavaScript components to enhance UX by reducing redundant operations and adding usage prompts, and interacted with OS to deploy multi-process and multi-thread tasks for MPEG-TS stream decoding and analysis\n•	Migrated legacy systems to RESTful APIs, ensured seamless integration with API Gateway services for customers, and produced comprehensive API documentation using Doxygen\n•	Fine-tuned MongoDB queries, achieving a performance boost from an average of 10s to 10ms through strategic indexing\n•	Ensured robust software quality by achieving 100% code coverage on unit tests for both functional and performance evaluations, and orchestrated UI testing using Selenium Python, complete with comprehensive documentation for tracking and reproducing bugs \n•	Dockerized project production to a docker image via Dockerfile, containerized scalable deployments using Kubernetes and deployed load-balanced replicas on AWS EKS",
        "image": "DTVI.jpg"
    },
    {
        "company": "Institute of Computer Technology, XDU",
        "title": "Software Engineer (Co-op)",
        "time": "Jan, 2022 - June, 2022",
        "Address": "China",
        "keywords": "Framework Development (C++), Reliability Enhancement, Database Design & Optimization, Data Management Modules",
        "description": "•	Implemented an open meteorological data center utilizing a self-developed C++ framework, achieved comprehensive encapsulation of different functionalities on Ubuntu system\n•	Engineered a heartbeat mechanism using shared memory, guaranteeing reliability of multiple processes\n•	Conceived and modeled Oracle DB objects with Power Designer, and revamped data handling operations, resulting in a staggering an average 1000% efficiency boost in large-scale data operations\n•	Implemented data management modules, guaranteeing optimal segregation based on Oracle Real Application Cluster, achieving a high concurrency rate of 3-5k requests/s through strategic implementation of Kafka, multi-threaded workers, and epoll optimization",
        "image": "XDU.jpg"
    },
    {
        "company": "ADAC Lab, NCSU",
        "title": "Software Engineer",
        "time": "July, 2021 - Aug, 2021",
        "Address": "Raleigh, NC",
        "keywords": "Massive Real-Time Data Visualization, Database Optimization, Distributed System Scalability",
        "description": "•	Designed a web interface to graphically represent battery gauge features, anchored on a massive real-time database capable of executing billion-level data queries\n•	Revitalized raw datasets by segregating them into strategically-indexed tables to reduce inter-table query numbers, leveraged an LRU mechanism for hotkey search caching, and transitioned historical data to cloud storage, resulting in data retrieval performance improving from an initial 12 seconds to an average of 1-2 seconds\n•	Initiated the deployment of read replicas to alleviate load on the primary database, offering either eventual or quorum level consistency as needed, and masterminded data sharding to distribute vast datasets across multiple machines for parallel processing, thereby reducing computing time from an average of 10 seconds to 3 seconds",
        "image": "NCSU.jpg"
    },{
        "company": "MyH2O",
        "title": "Data Researcher",
        "time": "Sept, 2020 - May, 2021",
        "Address": "China",
        "keywords": "Data Analysis & Modeling, ETL & Visualization",
        "description": "•	Meticulously dissected various features according to business stipulations using Pandas and Numpy, pinpointed high-impact features and architected logistic regression models, driving solution implementations\n•	Orchestrated ETL jobs for raw data stored in S3 buckets as CSV files, leveraging AWS Glue to extract, transform and load data\n•	Executed analyses using SparkSQL (RDD and DataFrame), enhanced data comprehension by visualizing results using Tableau",
        "image": "MyH2O.jpg"
    }
]

const Internship = () => {
    return(
        <>
             <div className='internship'>
                <div className='fly-text'>Cloud Computing</div>
                <div className='fly-text'>Software Engineering</div>
                <div className='fly-text'>Distributed Systems</div>
                <div className='fly-text'>Database</div>
                <div className='fly-text'>Data Analysis</div>
                <div className='fly-text'>Machine Learning</div>
                <div className='internship-area'>
                    <Row>
                        {internshipList.map((internship, index) => (
                            <Col key={index} xs={12} sm={8} md={6} lg={4}>
                                <InternCard intern={internship}/>
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>
        </>
    )
}

export default Internship;